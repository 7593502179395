import React, { useRef, useState } from 'react'
import Button from '../Button'
import Title from '../Title'
import Loader from '../Loader'
import Error from '../Error'
import reduceBlob from 'image-blob-reduce'
let heic2any
if (typeof window !== `undefined`) import('@tuily/heic2any').then(e => { console.log(e); heic2any = e.default })

export default function Upload({ upload, session }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const input = useRef(null)
  const handleFiles = async e => {
    let progress = 0
    const length = e.target.files.length
    const setProgress = value => {
      progress += value
      setLoading(progress / length)
    }
    console.log(e.target.files)
    const photos = e.target.files
    try {
      for await (const photo of photos) {
        setProgress(0.1)
        if (photo.type === 'image/heif' || photo.type === 'image/heic') {
          photo.converted = await heic2any({ blob: photo, toType: 'image/jpeg', quality: 0.8 })
        }
        const compressed = await reduceBlob().toBlob(photo.converted || photo, { max: 4000 }).catch(e => photo)
        setProgress(0.4)
        await upload(compressed)
        setProgress(0.5)
      }
    } catch (error) {
      console.error(error)
      setError(true)
    }
    setLoading(false)
  }
  if (error)
    return <Error>Erreur lors de la récupération de vos photos</Error>
  if (loading !== false)
    return <Loader title="Transfert des photos" progress={loading} />
  return (
    <div className="flex flex-col justify-center items-center text-center h-screen">
      {session.photos.length === 0 &&
        <Title>Ajoutez des photos</Title>
      }
      {session.photos.length > 0 &&
        <>
          <Title>Photos transférées</Title>
          <div>Vous pouvez ajouter d'autres photos</div>
        </>
      }

      <Button className="mt-4" onClick={() => input.current.click()}>Choisir</Button>
      <input
        className="fixed -z-10 hidden"
        type="file"
        ref={input}
        onChange={handleFiles}
        accept="image/*"
        multiple />
    </div>
  )
}
