import React, { useState } from 'react'
import Loader from '../components/Loader'
import Gradient from '../components/Gradient'
import Error from '../components/Error'
import Upload from '../components/upload/Upload'
import Connection from '../components/upload/Connection'
import { findSessionByCode, uploadPhoto } from '../api'


function UploadPage() {
  const [session, setSession] = useState(false)
  const connect = async code => {
    await setSession(undefined)
    setSession(await findSessionByCode(code))
  }
  const upload = async photo => {
    setSession(await uploadPhoto(session.code, [photo]))
  }
  if (session === undefined)
    return <Loader />
  if (session.error)
    return <Error>{session.error}</Error>
  if (session)
    return <Upload upload={upload} session={session} />
  return <Connection connect={connect} />
}

export default function UploadWithGradient() {
  return (
    <div>
      <style dangerouslySetInnerHTML={{ __html: `html { font-size: 150% }` }} />
      <Gradient />
      <UploadPage />
    </div>
  )
}